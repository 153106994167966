@import-normalize; /* bring in normalize.css styles */

:root {
  --client-height: 200px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

body {
  background-color: #151515;
  color: #fff;
  font-family: 'Montserrat';
}

.bm-burger-button {
  position: absolute;
  width: 50px;
  height: 50px;
  right: 18px;
  top: 14px;
}

.bm-burger-bars {
  background: black;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 80%;
}

/* General sidebar styles */
.bm-menu {
  padding-top: 50px;
  background: white;

  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: white;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;

  text-align: center;
}

/* Individual item */
.bm-item {
  margin-bottom: 40px;
  text-decoration: none;
  font-size: 30px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.react-multi-carousel-item {
  margin-top: auto;
  margin-bottom: auto;
}
